.applogo {

    width: 120px;
    height: 50px;
    background: #333;
    margin: 8px 24px 16px 0;
    float: left;
    background:  url('../img/istichara.png') 0 0 no-repeat padding-box !important;
}
.applogo p {
    margin-left: 60px;
    line-height: 3;
    font-weight: bold;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    font-size: large;
    color: #FFFFFF;
}
.navBar {
    justify-items: end;
    float: right;
}

.login_body{
    background: rgb(0, 214, 255);
    height:100vh;
}
.login_card{
    padding: 20px;
    border-radius: 15px;
}
.login_card_title{
    font-weight: bold;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    font-size: large;
    margin:20px;
    color: #0a0057;
}

